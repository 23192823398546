.btn-ban {
    @include button-variant($gray-800, $gray-800);
}

.btn-multiline {
    line-height: 1.2;
}

.btn-circle {
    &.btn-lg {
        padding: 0;
        width: 48px;
        height: 48px;
        border-radius: 50%;
    }
    &.btn-sm {
        padding: 0;
        width: 31px;
        height: 31px;
        border-radius: 50%; 
    }
    padding: 0;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}



// usare btn-outline-xxxxxxx + btn-ghost
.btn-ghost {
    border: transparent;
    background: transparent;
}


.btn-rounded {
    &.btn-sm {
        border-radius: 1rem;
    }

    &.btn {
        border-radius: 1.2rem;
    }
}

.btn-chip-left {
    .chip {
        position: absolute;
        left: -1px;
        top: -1px;
        bottom: -1px;
        width: 30px;
        border-radius: 50%;
        line-height: 2rem;
        background: rgba(#000 , 0.4);
    }
    position: relative;
    padding-left: 2rem;
}

.btn-chip-right {
    .chip {
        position: absolute;
        right: -1px;
        top: -1px;
        bottom: -1px;
        width: 30px;
        border-radius: 50%;
        line-height: 2rem;
        background: rgba(#000 , 0.4);
    }
    position: relative;
    padding-right: 2rem;
}

.btn-min-width-sm {
    min-width: 22rem;
}


.has-icon-left {
    i {
        margin-right: $spacer / 3
    }
}


// Fix colore btn-outline-secondary
.btn-outline-secondary {
    color: $gray-700
}



.btn {
    &.custom-control {
        padding-left: $spacer * 2;

        &:hover {
            cursor: pointer;
        }
    }
}



.btn-group-approvazione {

    .btn {
        min-width: 175px;

        i {
            margin-right: 4px;
        }
    }

    .btn-approvato {
        &.active {
            border-color: $success !important;
            background: $success !important;
            color: #fff !important;
        }
    }
    .btn-non-approvato {
        &.active {
            border-color: $danger !important;
            background: $danger !important;
            color: #fff !important;
        }
    }
}


.btn-xs {
    @include button-size(1px, 6px, $small-font-size, $btn-line-height-sm, $btn-border-radius-sm);
}