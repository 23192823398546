$sidebar-width: 290px;

$sidebar-header-height: 120px;
$sidebar-header-height-collapsed: $sidebar-header-height+52px;


$sidebar-collapse-breakpoint: 1200px;

#main-wrapper {
    position: relative;
    top: 0;
    height: 100vh;
}

#navbar-top {
    .sidebar-toggler {
        padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
        font-size: $navbar-toggler-font-size;
        line-height: 1;
        background-color: transparent;
        border: 0;
        margin-left: -10px;
        @include border-radius($navbar-toggler-border-radius);

        @include hover-focus {
            text-decoration: none;
        }

        // Opinionated: add "hand" cursor to non-disabled .navbar-toggler elements
        &:not(:disabled):not(.disabled) {
            cursor: pointer;
        }

        display: none;

        @media screen and (max-width: $sidebar-collapse-breakpoint) {
            display: inline;
        }
    }

    #main-logo {
        height: 38px;
    }

    #navbar-top-right {
        .btn {
            border-radius: 0;
        }

        .navbar-nav {
            @media screen and (max-width: $sidebar-collapse-breakpoint) {
                display: none;
            }
        }

        display: flex;
    }

    left: $sidebar-width;

    @media screen and (max-width: $sidebar-collapse-breakpoint) {
        left: 0;
        padding: 6px 0;
    }

    height: 51px;
}




#main-sidebar {
    color: #fff;
    width: $sidebar-width;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;

    transition: all 0.3s ease-in-out;
    z-index: $zindex-fixed-sidebar;

    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.34);

    @media screen and (max-width: $sidebar-collapse-breakpoint) {
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0);

        .sidebar-open & {
            transform: translateX(0%);
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.34);
        }

        transform: translateX(-100%);
    }

    #main-sidebar-header {
        height: $sidebar-header-height;
        background: $gray-700;

        display: flex;

        @media screen and (max-width: $sidebar-collapse-breakpoint) {
            height: $sidebar-header-height-collapsed;
        }

        #main-sidebar-header-inner {
            padding: 15px;
            display: flex;
            align-items: center;

            .main-sidebar-header-thumbnail {
                min-width: 75px;
            }

            .main-sidebar-header-label {
                display: flex;
                flex-direction: column;
                align-items: end;
                font-size: 14px;
            }
            .main-sidebar-header-label-name {
                line-height: 15px;
            }
            .main-sidebar-header-label-ruolo {
                line-height: 13px; 
            }
            .main-sidebar-header-label-line {
                margin-top: 1px;
                margin-bottom: 1px;
            }
        }

        img {
            margin-right: 10px;
        }

        #main-sidebar-user-menu {
            display: none;

            @media screen and (max-width: $sidebar-collapse-breakpoint) {
                display: block;
            }

            .btn {
                &:after {
                    position: absolute;
                    right: 10px; 
                    top: 22px;
                }
                &:hover {
                    background: lighten($gray-900, 10%);
                }
                border-radius: 0;
                position: relative;
                display: block;
                width: 100%;
                text-align: left;
                background: $gray-900;
                padding: 14px $btn-padding-x;
                border: transparent;
                height: 52px;
                color: #fff;
            }
        }
    }

    #main-sidebar-body {

        #btn-back {
            margin-bottom: $spacer * 2;
            background: $gray-500;
        
            a {
                &:hover {
                    background: $primary;
                }
            }
        }

        // Permette il posizionamento corretto dei tooltip di Shepherd
        // la classe viene aggiunta prima che il tooltip sia posizionato e rimossa subito dopo 
        &.overflow-fix {
            overflow: visible;
        }

        background: img-url('sidebar-bg.png');
        background-repeat: no-repeat;
        background-color: #2E332A;
        background-position: center top;
        background-size: 100% auto;

        height: calc(100% - #{$sidebar-header-height});
        overflow-x: auto;

        @media screen and (max-width: $sidebar-collapse-breakpoint) {
            height: calc(100% - #{$sidebar-header-height-collapsed});
        }

        .nav {
            padding: 40px 0;


            .nav-item {
                position: relative;

                &.tooltip-sidebar {
                    &:before {
                        pointer-events: none;
                        display: block;
                        width: 100%;
                        height: auto;
                        content: attr(title);
                        z-index: 999;
                        padding: 10px 20px;
                        position: absolute;
                        background: $modal-backdrop-bg;
                        font-size: $font-size-sm;
                        line-height: 1.1;
                        opacity: 0;
                        visibility: 0;
                        transition: all 0.1s ease-in-out;
                        transform: translate(0 , -30%);
                    }
                    &:hover {
                        &:before {
                            visibility: 1;
                            opacity: 1;
                            display: block;
                            transform: translate(0 , -100%);
                        }
                    }
                }

            }
            .nav-link {

                border-left: solid 10px transparent;
                color: #fff;
                padding-left: 10px;

                &.disabled {
                    &:hover {
                        background: transparent;
                        cursor: not-allowed;
                    }

                    opacity: 0.4;
                }

                &.active {
                    &:hover {
                        background: rgba(#fff, 0.4);
                    }

                    cursor: default;
                    border-left: solid 10px red;
                    background: rgba(#fff, 0.4);
                }

                &:hover {
                    background: rgba(#fff, 0.1);
                }

                .fas {
                    margin-right: 6px;
                    width: 18px;
                    text-align: center;
                }
            }

            
        }
    }

}

#dropdown-help {
    .dropdown-item {
        i {
            width: 18px;
            margin-right: 5px;
            text-align: center;
            font-size: 14px;
        }
    }
}



#sidebar-backdrop {
    display: none;

    @media screen and (max-width: $sidebar-collapse-breakpoint) {
        display: block;
    }
}

#main-body {
    top: 70px;
    position: relative;
    float: right;
    width: calc(100% - #{$sidebar-width});

    @media screen and (max-width: $sidebar-collapse-breakpoint) {
        width: 100%;
    }
}



.page-header {
    border-bottom: 1px solid $border-color;
    margin-bottom: map-get($spacers, 4);
}


.approvazione-actions {
    .btn {
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 0.5rem;
    }
}



.main-content {
    padding-top: 50px;
    padding-bottom: 20vh;
}


.help-box {
    background: $gray-100;
    border-left: 4px solid $primary;
    padding: $alert-padding-y $alert-padding-x;
    margin-bottom: $spacer;
}


.list-view {
    .summary {
        margin-bottom: 1rem;
    }
}


.icon-state {
    width: 1.2rem;
    text-align: center;
    margin-right: $spacer / 2;
}