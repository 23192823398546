


.modal {
    &.show {
        .modal-dialog {
            &.modal-full {
                min-width: 100%;
                margin: 0;
                transform: scale(1);
            }
        }
    }
    .modal-dialog {
        &.modal-full {
            min-width: 100%;
            margin: 0;
            transform: scale(0.95);
        }
    }
}


.modal-full{
    .modal-content {
        border: none;
        border-radius: 0;
        min-height: 100vh;
    }
}

#modal-associazione {
    .modal-body {
        padding: 0;
    }
}