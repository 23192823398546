.card-accordion {

}


.accordion-light {
    .accordion-light-single {
        border-bottom: 1px solid $gray-100;
        .accordion-light-single-header {
            .btn {
                position: relative;
                padding: 1rem 0;
        
                &:after {
                    width: 20px;
                    height: 20px;
                    color: $body-color;
                    display: flex;
                    font-family: 'Font Awesome 5 Pro';
                    font-weight: 900;
                    content: "\f138";
                    position: absolute;
                    top: calc(50% - 10px);
                    right: 0;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.15s ease-in-out;
                    transform: rotate(90deg);
                }
        
                &.collapsed {
                    &:after {
                        transform: rotate(0deg);
                    }
                }
            }
        }
    }


    &.accordion-flat {
        .card, .card-header {
            border-radius: 0 !important;
        }
    }

}