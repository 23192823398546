/*
*   Badge circolari
*/
.badge-circle {
  border-radius: 50%;
  padding: 0;
  background: $gray-700;
  color: #fff;
  text-align: center;
  display: inline-block;
}

/*
.badge-xs {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 14px;
}

.badge-sm {
    width: 34px;
    height: 34px;
    line-height: 34px;
    font-size: 15px;
}

.badge-md {
    width: 42px;
    height: 42px;
    line-height: 42px;
    font-size: 20px;
}

.badge-lg {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 22px;
}

.badge-xl {
    width: 56px;
    height: 56px;
    line-height: 56px;
    font-size: 26px;
}
*/

.badge-stato {
  font-size: 1rem;
  padding: 0.3rem 0.7rem;

  @include border-radius($badge-pill-border-radius);
  i {
    margin-right: 5px;
  }
}

/*
*   Barra di avanzamento approvazione
*/
.badge-container {
  .badge {
    left: -21px;
    top: -12px;
    position: relative;
  }

  width: 0;
  height: 0;
  position: relative;
}

.progress-approvazione {
  .progress {
    flex-grow: 1;
  }

  .badge {
    &.bg-success {
      border-color: $success;
      color: #fff;
    }

    &.bg-info {
      border-color: $info;
      color: #fff;
    }

    background: #fff;
    color: $progress-bg;
    background: #fff;
    border: 5px solid $progress-bg;
    line-height: 34px;
  }

  padding: 15px 22px;
  margin-bottom: 20px;
  display: flex;
}

/*
*   Box messaggi
*/
.event-hystory {
  &.loading {
    .msg-box-4 {
      animation: msg-box-4 1s infinite;
    }

    .msg-box-3 {
      animation: msg-box-3 1s infinite;
    }

    .msg-box-2 {
      animation: msg-box-2 1s infinite;
    }

    .msg-box-1 {
      animation: msg-box-1 1s infinite;
    }

    .btn-load-hystory {
      opacity: 0;
    }
  }

  .msg-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-height: 100px;
    transition: all 0.3s ease-in-out;
  }

  .msg-box-4 {
    transform: translate3d(0, 60px, -30px);
    opacity: 0;
    filter: blur(3px);
  }

  .msg-box-3 {
    transform: translate3d(0, 60px, -20px);
    opacity: 1;
    filter: blur(2px);
  }

  .msg-box-2 {
    transform: translate3d(0, 60px, -10px);
    opacity: 1;
    filter: blur(1px);
  }

  .msg-box-1 {
    transform: translate3d(0, 60px, 0);
    opacity: 1;
    filter: blur(0px);
  }

  .btn-load-hystory {
    position: absolute;
    z-index: 10;
    top: 91px;
    left: 50%;
    transform: translateX(-50%);
  }

  perspective: 78px;
  height: 160px;
  margin-bottom: 70px;
  perspective-origin: center -170px;
}

@keyframes msg-box-4 {
  from {
    transform: translate3d(0, 60px, -30px);
    opacity: 0;
    filter: blur(3px);
  }

  to {
    transform: translate3d(0, 60px, -20px);
    opacity: 1;
    filter: blur(2px);
  }
}

@keyframes msg-box-3 {
  from {
    transform: translate3d(0, 60px, -20px);
    filter: blur(2px);
  }

  to {
    transform: translate3d(0, 60px, -10px);
    filter: blur(1px);
  }
}

@keyframes msg-box-2 {
  from {
    transform: translate3d(0, 60px, -10px);
    filter: blur(1px);
  }

  to {
    transform: translate3d(0, 60px, 0);
    filter: blur(0px);
  }
}

@keyframes msg-box-1 {
  from {
    transform: translate3d(0, 60px, 0);
    opacity: 1;
  }

  to {
    transform: translate3d(0, 60px, 10px);
    opacity: 0;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0),
      0px 5px 8px 0px rgba(0, 0, 0, 0), 0px 1px 14px 0px rgba(0, 0, 0, 0);
  }
}

.msg-box {
  &.corner-top-left {
    .badge {
      left: -60px;
    }

    &:before {
      position: absolute;
      content: " ";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 0;
      border-color: transparent $gray-100 transparent transparent;
      left: -10px;
      top: -1px;
    }

    border-top-left-radius: 0;
  }

  &.corner-top-right {
    .badge {
      right: -60px;
    }

    &:before {
      position: absolute;
      content: " ";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 0;
      border-color: $gray-100 transparent transparent transparent;
      top: -1px;
      right: -10px;
    }

    border-top-right-radius: 0;
  }

  .badge {
    top: -22px;
    position: absolute;
  }

  .msg-box-footer {
    text-align: right;
  }

  .alert {
    margin: 0 -11px 10px;
    padding: 10px;
    border-radius: 0;
  }

  padding: 10px;
  @include mat-elevation(5);
  position: relative;
  background: $gray-100;
  margin-bottom: $alert-margin-bottom * 2;
}

.repeatable-box {
  .repeatable-box-fields {
    .form-group {
      flex-grow: 1;
      margin-left: 5px;
      margin-right: 5px;
    }

    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: -1rem;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
  }

  .repeatable-box-actions {
    padding-left: 0.5rem;
    padding-top: 32px;
  }

  display: flex;
  padding: 1rem;
  background: $gray-100;
  @include mat-elevation(2);
  margin-bottom: 0.5rem;
}

$page-header-switcher-breack: 800px;

.box-azienda-switcher {
  .box-azienda-switcher-txt {
    margin-right: 0.5rem;
    text-align: right;

    @media screen and (max-width: $page-header-switcher-breack) {
      text-align: left;
      margin-right: 0;
      margin-left: 0.5rem;
    }
  }

  margin-bottom: 0.5rem;
  display: flex;

  @media screen and (max-width: $page-header-switcher-breack) {
    flex-direction: row-reverse;
  }
}

.page-header-switcher {
  h1 {
    flex-grow: 1;
  }

  display: flex;

  @media screen and (max-width: $page-header-switcher-breack) {
    flex-direction: column;
  }

  .box-azienda-switcher {
    @media screen and (max-width: $page-header-switcher-breack) {
      justify-content: flex-end;
    }

    flex-shrink: 0;
  }
}

button {
  &.img-thumbnail {
    &:hover {
      background: $primary;
      cursor: pointer;
    }
  }
}

.thumb-select-azienda-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .thumb-select-azienda {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 5px;

    .thumb-select-azienda-img {
      width: 110px;
      height: auto;
    }
  }
}

.section-title {
  border-bottom: 1px solid $gray-200;
  margin-bottom: 1.5rem;
}

.section-title-2 {
  border-left: 7px solid $primary;
  padding-left: 4px;
  margin-bottom: 1.5rem;
}

.dropdown-stato {
  .btn {
    min-width: 160px;
  }

  .dropdown-toggle {
    text-align: left;
    position: relative;

    &:after {
      position: absolute;
      right: 10px;
      top: calc(50% - 2px);
    }
  }

  .dropdown-menu {
    padding: 0;
    border: 0;
    margin: 0;

    .btn {
      display: block;
      width: 100%;
      clear: both;
      text-align: inherit;
      white-space: nowrap;
      margin: 4px 0;
    }
  }
}

.pointer-events-none {
  pointer-events: none;
}

.couter-chip {
  border: 1px solid $input-border-color;
  height: 40px;
  border-radius: 20px;
  display: flex;
  display: inline-flex;
  align-items: center;
  font-weight: bold;

  .couter-chip-number {
    height: 40px;
    border-radius: 20px;
    background: $primary;
    color: #fff;
    display: inline-flex;
    align-items: center;
    padding: 0 15px;
    margin-left: -1px;
  }

  .couter-chip-label {
    padding: 0 15px 0 10px;
  }
}

#snackbar-prodotto {
  background: $gray-900;
  color: #fff;
  border-radius: $border-radius-sm;
  height: auto;
  width: auto;
  display: flex;
  position: fixed;
  bottom: $spacer;
  left: 50%;
  z-index: 999999;
  transform: translateX(-50%);
  display: flex;
  padding: $spacer / 2 $spacer;
  align-items: center;
  box-shadow: $box-shadow;

  #snackbar-prodotto-content {
    margin-right: $spacer * 2;
  }
  #snackbar-prodotto-actions {
    .btn {
      line-height: 1.3;
    }

    .btn-primary {
      color: $primary;
      font-weight: bold;

      &:hover {
        color: #fff;
      }
    }
  }
}

.modal-select-azienda-globale-container {
  color: #3c4038;
}
#main-sidebar #main-sidebar-header .modal-select-azienda-globale-container img {
  margin-right: 0px;
}