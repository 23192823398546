#navbar-top-right {
    .nav-link {
        &:hover {
            cursor: pointer;
        }
    }
}




.navbar-brand {
    img{
        height: 40px;
    }
    padding: 0;
}

.navbar-small {
    padding: 0 $navbar-padding-x; 
}






.navbar-dark {
  .navbar-brand {
    
  }

  .navbar-nav {
    .nav-link {
      &.disabled {
        
      }
      border-bottom: 3px solid transparent;
      padding: 12px 22px;
      color: #fff;
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
        background: rgba(#ffffff, 0.2);
        border-bottom-color: $secondary;
    }
    
  }

  .navbar-toggler {
  }

  .navbar-toggler-icon {
  }

  .navbar-text {
    a {

    }
  }
}
