.grid-box-state {
    display: flex;
    flex-wrap: wrap;

    .box-state {
        margin: 15px;
        flex-shrink: 0;
    }
}

.box-state {
    color: $body-color;
    display: flex;
    width: 300px;
    position: relative;
    padding: 0 22px 7px 10px;
    border-radius: $btn-border-radius/2;
    overflow: hidden;
    background: #fff;

    &:after {
        display: flex;
        background: $gray-200;
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        content: "\f105";
        color: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 22px;
        justify-content: center;
        align-items: center;
    }

    &:hover {
        text-decoration: none;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    }
    
    &.click-disable {
        pointer-events: none;
        
        &:after {
            display: none;
        }
    }

    .box-state-icon {
        width: 25%;
        display: flex;
        vertical-align: middle;
        align-items: center;
        justify-content: center;
    }

    .box-state-txt {
        width: 75%;
        padding-left: 10px;

        .box-state-txt-number { 
            font-size: 4rem;
            font-weight: bold;
            line-height: 1;
        }

        .box-state-txt-ntt {
            line-height: 1.1;
        }

        .box-state-txt-stato {
            font-weight: bold;
            font-size: $font-size-lg;
            line-height: 1;
        }
    }
}

.badge-state-circle {
    width: 56px;
    height: 56px;
    line-height: 56px;
    font-size: 26px;
}


.section-box-state {
    background-repeat: no-repeat;
    background-position: 0 0;
    padding: 0 0 40px 0;

    @media screen and (min-width: 1800px) {
        background-position: 5% 0;
    }
}

.section-aziendeTerze {
    background-image: url(../img/art-aziendeTerze.svg);
}

.section-materiePrime {
    background-image: url(../img/art-materiePrime.svg);
}

.section-prodotti {
    background-image: url(../img/art-prodotti.svg);
}

.section-stabilimenti {
    background-image: url(../img/art-stabilimenti.svg);
}