.field-max-width {
    .form-control {
        max-width: 440px;
    }
}

textarea.form-control {
    height: 100px;
}

// fix input custom
.custom-checkbox .custom-control-label::before {
    border: 1px solid $gray-200;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    border: 1px solid transparent;
}

.custom-radio .custom-control-label::before {
    border: 1px solid $gray-200;
}
.custom-control-input:checked ~ .custom-control-label::before {
    border: 1px solid transparent;
}


.btn-checkbox-inside {
    label {
        margin: 0;
    }
}


// Asterisco sui campi required
div.required {

    label:not(.custom-control-label):after {
        content: " *";
        color: $danger;
    } 

}