.single-prodotto-val-header {
    border-bottom: 1px solid $gray-200;
    margin-bottom: $spacer * 2;

    .table-info-prodotto {
        th {
            width: 120px;
        }
    }
}



.badge-company {
    display: flex;
    margin-bottom: $spacer;

    img {
        width: 85px;
        height: auto;
        margin-right: 10px;
        ;
    }
}

// https://www.codeply.com/go/adHucFecSs/bootstrap-dropdown-as-select
.btn-group-approvazione {
    .btn {
        padding-left: $spacer ;
        padding-right: $spacer ;
    }
    .btn:not(.active){
        background-color: transparent;
        color: $gray-500;
        border-color: $gray-300;

        &:hover {
            background-color: $gray-300;
            color: #fff;
            border-color: $gray-300;
        }
    }
}