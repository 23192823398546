

@function img-url($image){
    @return url(#{$img-url}#{$image});
}

@function fonts-url($image){
    @return url(#{$fonts-url}#{$image});
}

