.tab-pane {
    padding-top: $spacer * 2;
}

#dropdown-language-list {
    img {
        height: 13px;
        margin-right: 5px;
    }
}

.nav-tabs {
    .nav-item {
        &:last-child {
            margin-right: 0;
        }
        margin-right: 3px;
    }
    .nav-link {
        font-size: 1.2rem;
        font-weight: bold;
        background: $gray-100;
        border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
        padding-left: $spacer * 2;
        padding-right: $spacer * 2;
    }
}