a , button {
    transition: all 0.15s ease-in-out;
}



.file-link {
    &:before {
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        content: "\f15c";
        font-size: 13px;
        margin: 0 3px;
    }
}