

#selettore-tipo-azienda {
    #azienda-isgdoandproducer {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;

        .radio-el {
            display: flex;
            input {
                margin-top: 2px;
                margin-right: 10px;
            }
            label {
                cursor: pointer;
            }
        }
    }
}