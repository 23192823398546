.card-header {
    .btn-collapse-card {
        position: relative;
        padding-left: 0;
        padding-right: 0;

        &:after {
            width: 20px;
            height: 20px;
            color: $body-color;
            display: flex;
            font-family: 'Font Awesome 5 Pro';
            font-weight: 900;
            content: "\f138";
            position: absolute;
            top: calc(50% - 10px);
            right: 0;
            align-items: center;
            justify-content: center;
            transition: all 0.15s ease-in-out;
            transform: rotate(90deg);
        }

        &.collapsed {
            &:after {
                transform: rotate(0deg);
            }
        }
    }
}


.card-header-badge {
    position: absolute;
    top: -10px;
    right: -10px;
}



.card-grammatura {
    .card-header {
        border: 0;
    }
    .card-body {
        padding-top: 0;
        padding-bottom: 0;

        .section-title-2 {
            margin: 1rem 0;
        }

        .card-grammatura-collapse {
            margin-left: -$card-spacer-x;
            margin-right: -$card-spacer-x;

            .inner {
                border-top: 1px solid $gray-200;
                padding: $card-spacer-x $card-spacer-y;
            }
        }
    }
}


