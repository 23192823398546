.sidebar {
    position: fixed;
    right: 0;
    bottom: 0;
    top: 51px;
    background: #fff;
    width: 100%;
    max-width: 700px;
    z-index: $zindex-fixed;
    box-shadow: $box-shadow;
    display: flex;
    flex-direction: column;

    transform: translateX(120%);

    transition: transform 0.3s ease-in-out;

    &.open {
        transform: translateX(0);
    }

    .sidebar-header {
        position: relative;
        z-index: 10;
        display: flex;
        padding: $modal-header-padding;  
        border-bottom: $modal-header-border-width solid $modal-header-border-color;

        box-shadow: 0 .125rem .25rem rgba($black, .075);

        .sidebar-header-title {
            flex-grow: 1;
            margin: 0;
        }
        .close {
            padding: $modal-header-padding;
            margin: (-$modal-header-padding) (-$modal-header-padding) (-$modal-header-padding) auto;
          }
    }

    .sidebar-body {
        position: relative;
        z-index: 5;
        flex-grow: 1;
        padding: $modal-inner-padding;
        overflow: auto;
    }

    .sidebar-footer {
        position: relative;
        z-index: 10;
        padding: $modal-header-padding;  
        border-top: $modal-header-border-width solid $modal-header-border-color;

        box-shadow: 0 -.125rem .25rem rgba($black, .075);
    }
}

#sidebar-video-tutorial {
    .accordion-flat {
        margin-left:  -$modal-inner-padding;
        margin-right:  -$modal-inner-padding;
        
        .card-header {
            padding: 0;

            .btn {
                padding: $modal-header-padding;
                &:after {
                    right: $modal-header-padding;;
                }
            }
        }
        .card-body {
            padding: $modal-header-padding;
        }
    }
}