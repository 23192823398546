#stage-associazione {

    .col-header {
        background: $table-accent-bg;
        padding: 0.5rem;
    }

    #col-sx {
        border-right: 1px solid $gray-200;
    }
    #col-dx{
        .prodotto-btn-move {
            margin-right: 0.5rem;
        }
    }
    .checkboxselect-all-produttore {
        .custom-control {
            input {
                display: none
            }
            margin-right: 0;
        }
        margin-right: -5px;
        
    }
    .col {
        .col-header {

        }
        .col-body {
            position: absolute;
            top: 75px;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: auto;
            //background: red;
        }
        position: relative;
        width: 50%;
        height: 100%;
        padding: 0;
        //background: green;
    }

    .lista-prodotti {
        .lettera-iniziale {
            padding: 0.5rem 1rem 0.5rem 2.8rem;
            font-weight: bold;
            font-size: 30px;
        }
        .prodotto-single {
            border-bottom: 1px solid $gray-200;
            margin: 0.5rem;
            padding: 0.5rem 0;
            display: flex;
            justify-content: flex-start;
        }
        .prodotto-check {

        }
        .prodotto-info {
            flex-grow: 1;
        }
        .prodotto-btn-move {
            
        }
        list-style: none;
        padding: 0;
    }

    height: calc(100vh - 102px);
    width: 100%;
    //background: red;
    display: block;
    position: relative;
    display: flex;
}