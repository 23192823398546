
$mat-fast-out-slow-in-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !default;
// A collection of mixins and CSS classes that can be used to apply elevation to a material
// element.
// See: https://www.google.com/design/spec/what-is-material/elevation-shadows.html
// Examples:
//
//
// .mat-foo {
//   @include $mat-elevation(2);
//
//   &:active {
//     @include $mat-elevation(8);
//   }
// }
//
// <div id="external-card" class="mat-elevation-z2"><p>Some content</p></div>
//
// For an explanation of the design behind how elevation is implemented, see the design doc at
// https://goo.gl/Kq0k9Z.

// Colors for umbra, penumbra, and ambient shadows. As described in the design doc, each elevation
// level is created using a set of 3 shadow values, one for umbra (the shadow representing the
// space completely obscured by an object relative to its light source), one for penumbra (the
// space partially obscured by an object), and one for ambient (the space which contains the object
// itself). For a further explanation of these terms and their meanings, see
// https://en.wikipedia.org/wiki/Umbra,_penumbra_and_antumbra.

// Maps for the different shadow sets and their values within each z-space. These values were
// created by taking a few reference shadow sets created by Google's Designers and interpolating
// all of the values between them.

@function _get-umbra-map($color) {
    @return (
      0: '0px 0px 0px 0px #{rgba($color, 0.2)}',
      1: '0px 2px 1px -1px #{rgba($color, 0.2)}',
      2: '0px 3px 1px -2px #{rgba($color, 0.2)}',
      3: '0px 3px 3px -2px #{rgba($color, 0.2)}',
      4: '0px 2px 4px -1px #{rgba($color, 0.2)}',
      5: '0px 3px 5px -1px #{rgba($color, 0.2)}',
      6: '0px 3px 5px -1px #{rgba($color, 0.2)}',
      7: '0px 4px 5px -2px #{rgba($color, 0.2)}',
      8: '0px 5px 5px -3px #{rgba($color, 0.2)}',
      9: '0px 5px 6px -3px #{rgba($color, 0.2)}',
      10: '0px 6px 6px -3px #{rgba($color, 0.2)}',
      11: '0px 6px 7px -4px #{rgba($color, 0.2)}',
      12: '0px 7px 8px -4px #{rgba($color, 0.2)}',
      13: '0px 7px 8px -4px #{rgba($color, 0.2)}',
      14: '0px 7px 9px -4px #{rgba($color, 0.2)}',
      15: '0px 8px 9px -5px #{rgba($color, 0.2)}',
      16: '0px 8px 10px -5px #{rgba($color, 0.2)}',
      17: '0px 8px 11px -5px #{rgba($color, 0.2)}',
      18: '0px 9px 11px -5px #{rgba($color, 0.2)}',
      19: '0px 9px 12px -6px #{rgba($color, 0.2)}',
      20: '0px 10px 13px -6px #{rgba($color, 0.2)}',
      21: '0px 10px 13px -6px #{rgba($color, 0.2)}',
      22: '0px 10px 14px -6px #{rgba($color, 0.2)}',
      23: '0px 11px 14px -7px #{rgba($color, 0.2)}',
      24: '0px 11px 15px -7px #{rgba($color, 0.2)}'
    );
  }
  
  @function _get-penumbra-map($color) {
    @return (
      0: '0px 0px 0px 0px #{rgba($color, 0.14)}',
      1: '0px 1px 1px 0px #{rgba($color, 0.14)}',
      2: '0px 2px 2px 0px #{rgba($color, 0.14)}',
      3: '0px 3px 4px 0px #{rgba($color, 0.14)}',
      4: '0px 4px 5px 0px #{rgba($color, 0.14)}',
      5: '0px 5px 8px 0px #{rgba($color, 0.14)}',
      6: '0px 6px 10px 0px #{rgba($color, 0.14)}',
      7: '0px 7px 10px 1px #{rgba($color, 0.14)}',
      8: '0px 8px 10px 1px #{rgba($color, 0.14)}',
      9: '0px 9px 12px 1px #{rgba($color, 0.14)}',
      10: '0px 10px 14px 1px #{rgba($color, 0.14)}',
      11: '0px 11px 15px 1px #{rgba($color, 0.14)}',
      12: '0px 12px 17px 2px #{rgba($color, 0.14)}',
      13: '0px 13px 19px 2px #{rgba($color, 0.14)}',
      14: '0px 14px 21px 2px #{rgba($color, 0.14)}',
      15: '0px 15px 22px 2px #{rgba($color, 0.14)}',
      16: '0px 16px 24px 2px #{rgba($color, 0.14)}',
      17: '0px 17px 26px 2px #{rgba($color, 0.14)}',
      18: '0px 18px 28px 2px #{rgba($color, 0.14)}',
      19: '0px 19px 29px 2px #{rgba($color, 0.14)}',
      20: '0px 20px 31px 3px #{rgba($color, 0.14)}',
      21: '0px 21px 33px 3px #{rgba($color, 0.14)}',
      22: '0px 22px 35px 3px #{rgba($color, 0.14)}',
      23: '0px 23px 36px 3px #{rgba($color, 0.14)}',
      24: '0px 24px 38px 3px #{rgba($color, 0.14)}'
    );
  }
  
  @function _get-ambient-map($color) {
    @return (
      0: '0px 0px 0px 0px #{rgba($color, 0.12)}',
      1: '0px 1px 3px 0px #{rgba($color, 0.12)}',
      2: '0px 1px 5px 0px #{rgba($color, 0.12)}',
      3: '0px 1px 8px 0px #{rgba($color, 0.12)}',
      4: '0px 1px 10px 0px #{rgba($color, 0.12)}',
      5: '0px 1px 14px 0px #{rgba($color, 0.12)}',
      6: '0px 1px 18px 0px #{rgba($color, 0.12)}',
      7: '0px 2px 16px 1px #{rgba($color, 0.12)}',
      8: '0px 3px 14px 2px #{rgba($color, 0.12)}',
      9: '0px 3px 16px 2px #{rgba($color, 0.12)}',
      10: '0px 4px 18px 3px #{rgba($color, 0.12)}',
      11: '0px 4px 20px 3px #{rgba($color, 0.12)}',
      12: '0px 5px 22px 4px #{rgba($color, 0.12)}',
      13: '0px 5px 24px 4px #{rgba($color, 0.12)}',
      14: '0px 5px 26px 4px #{rgba($color, 0.12)}',
      15: '0px 6px 28px 5px #{rgba($color, 0.12)}',
      16: '0px 6px 30px 5px #{rgba($color, 0.12)}',
      17: '0px 6px 32px 5px #{rgba($color, 0.12)}',
      18: '0px 7px 34px 6px #{rgba($color, 0.12)}',
      19: '0px 7px 36px 6px #{rgba($color, 0.12)}',
      20: '0px 8px 38px 7px #{rgba($color, 0.12)}',
      21: '0px 8px 40px 7px #{rgba($color, 0.12)}',
      22: '0px 8px 42px 7px #{rgba($color, 0.12)}',
      23: '0px 9px 44px 8px #{rgba($color, 0.12)}',
      24: '0px 9px 46px 8px #{rgba($color, 0.12)}'
    );
  }
  
  // The default duration value for elevation transitions.
  $mat-elevation-transition-duration: 280ms !default;
  
  // The default easing value for elevation transitions.
  $mat-elevation-transition-timing-function: $mat-fast-out-slow-in-timing-function;
  
  // Prefix for elevation-related selectors.
  $_mat-elevation-prefix: 'mat-elevation-z';
  
  // Applies the correct css rules to an element to give it the elevation specified by $zValue.
  // The $zValue must be between 0 and 24.
  @mixin mat-elevation($zValue, $color: black) {
    @if type-of($zValue) != number or not unitless($zValue) {
      @error '$zValue must be a unitless number';
    }
    @if $zValue < 0 or $zValue > 24 {
      @error '$zValue must be between 0 and 24';
    }
  
    box-shadow: #{map-get(_get-umbra-map($color), $zValue)},
                #{map-get(_get-penumbra-map($color), $zValue)},
                #{map-get(_get-ambient-map($color), $zValue)};
  }
  
  // Applies the elevation to an element in a manner that allows
  // consumers to override it via the Material elevation classes.
  @mixin mat-overridable-elevation($zValue) {
    &:not([class*='#{$_mat-elevation-prefix}']) {
      @include mat-elevation($zValue);
    }
  }
  
  // Returns a string that can be used as the value for a transition property for elevation.
  // Calling this function directly is useful in situations where a component needs to transition
  // more than one property.
  //
  // .foo {
  //   transition: mat-elevation-transition-property-value(), opacity 100ms ease;
  // }
  @function mat-elevation-transition-property-value(
      $duration: $mat-elevation-transition-duration,
      $easing: $mat-elevation-transition-timing-function) {
    @return box-shadow #{$duration} #{$easing};
  }
  
  // Applies the correct css rules needed to have an element transition between elevations.
  // This mixin should be applied to elements whose elevation values will change depending on their
  // context (e.g. when active or disabled).
  //
  // NOTE(traviskaufman): Both this mixin and the above function use default parameters so they can
  // be used in the same way by clients.
  @mixin mat-elevation-transition(
      $duration: $mat-elevation-transition-duration,
      $easing: $mat-elevation-transition-timing-function) {
    transition: mat-elevation-transition-property-value($duration, $easing);
  }