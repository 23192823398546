
$doc-breakpoint: 800px;



body[class^='page-guida'] {
    #main-body {
        top: 50px;
    }
    .main-content {
        padding-top: 0;
        padding-bottom: 0;
    }
}


#doc-nav {
    ul {
        ul {
            padding: 0.5rem 0 0.5rem 1rem;
            li {
                font-size: $font-size-sm;
            }
        }
    }
    @media screen and (max-width: $doc-breakpoint) {
        padding: 0;
    }
}


.doc-row {
    @media screen and (max-width: $doc-breakpoint) {
        display: block;
    }
}

.doc-col-nav {
    height: calc(100vh - 50px);
    overflow: auto;
    flex-grow: 0;
    flex-basis: 300px;
    padding-top: 50px;

    @media screen and (max-width: $doc-breakpoint) {
        height: auto;
        overflow: visible;
    }
}
.doc-col-content {
    height: calc(100vh - 50px);
    overflow: auto;
    padding-top: 50px;
    border-left: 1px solid $gray-300;

    @media screen and (max-width: $doc-breakpoint) {
        height: auto;
        overflow: visible;
        border-left: 0;
        padding-bottom: 100px;
    }

    .doc-col-wrapper {

        max-width: 800px;

        *:first-child {
            margin-top: 0;
        }
    } 
}



.text-body {
    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6 {
        margin-top: 5rem;
        margin-bottom: 2rem;
        position: relative;

        &:after {
            content: " ";
            display: block;
            width: 150px;
            height: 1px;
            background: $gray-200;
            position: absolute;
            left: 0;
            bottom: -1rem;
        }

    }
}


#btn-back-to-top {
    display: none;
    position: fixed;
    right: 1rem;
    bottom: 1rem;

    @media screen and (max-width: $doc-breakpoint) {
        display: inline-block;
    }
}

.lightbox {
    position: relative;
    display: inline-block;
    
    &:after {
        opacity: 0;
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        content: "\f00e";
        color: rgba(#fff , 0.9);
        position: absolute;
        z-index: 10;
        width: 30px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: rgba(#000 , 0.6);
        top: 0;
        right: 0;
        transition: opacity 0.3s ease-in-out;
    }

    &:hover {
        &:after {
            opacity: 1;
        }
    }
}
