.page-template-login {

    body {
        background: url(../img/backgroung.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top;
        padding-top: 6%;
        padding-bottom: 6%;
        min-height: 100vh;
    }

}


.login-box {
    .login-box-header {
        img {
            max-width: 280px;
            margin: 1rem;
        }
        text-align: center;
        color: #fff;
        background: $primary;
    }
    .login-box-header-socket {
        background: rgba(#000 , 0.2);
    }
    .login-box-body {
        background: #fff;
        padding: 1.5rem;
    }
    max-width: 600px;
    margin: auto;
    @include mat-elevation(20);
    
    

    @media screen and (max-width: 600px) {
        margin-top: 0;
    }

}
