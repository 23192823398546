

/*

.shepherd-modal-overlay-container {
    &.shepherd-modal-is-visible {
        height: 100vh;
        opacity: .5;
        transition: all .3s ease-out,height 0s 0s,opacity .3s 0s;
    }

    -ms-filter: progid:dximagetransform.microsoft.gradient.alpha(Opacity=50);
    filter: alpha(opacity=50);
    fill-rule: evenodd;
    height: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: all .3s ease-out,height 0ms .3s,opacity .3s 0ms;
    width: 100vw;
    z-index: 9997;
}

*/

.shepherd-has-video {
    max-width: 1200px;
    width: 100%;
}

