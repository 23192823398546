#modal-timeline {
    padding-top: 30px;
    .modal-dialog {
        width: 100%;
        max-width: none;
        margin: 0;
        padding: 0;
    }
}

#btn-modal-timeline-close {
    position: fixed;
    top: 10px;
    right: 10px;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: $zindex-fixed;
}



$timeline-badge-size: 64px;
$timeline-breackingPoint: 1024px;

.timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;

    @include clearfix();

    &:before {
        top: 0;
        bottom: 0;
        position: absolute;
        content: " ";
        width: 3px;
        background-color: #eeeeee;
        left: 50%;
        margin-left: -1.5px;

        @media (max-width: $timeline-breackingPoint) {
            left: 10px;
        }
    }



    >li {
        margin-top: -20px;
        margin-bottom: -20px;
        position: relative;
        width: 100%;
        float: left;
        //clear: left;

        @include clearfix();

        @media (max-width: $timeline-breackingPoint) {
            margin-bottom: 20px;
            position: relative;
            width: 100%;
            float: left;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .timeline-single {
            position: relative;
            width: 50%;

            @include clearfix();

            @media (max-width: $timeline-breackingPoint) {
                width: 100%;
            }
        }
        &.timeline-inverted {
            .timeline-single {
                float: right;
            }
        }

        // card left
        .card {
            font-size: 13px;

            position: relative;
            margin-right: $timeline-badge-size / 2 + 20px;

            @media (max-width: $timeline-breackingPoint) {
                float: right;
                width: calc(100% - 46px);
                margin-right: 0;
            }

            // indicatore laterale
            &:before {
                position: absolute;
                top: 69px;
                right: -17px;
                display: inline-block;
                border-top: 17px solid transparent;
                border-left: 17px solid #DFDFDF;
                border-right: 0 solid #DFDFDF;
                border-bottom: 17px solid transparent;
                content: " ";

                @media (max-width: $timeline-breackingPoint) {
                    border-left-width: 0;
                    border-right-width: 15px;
                    left: -15px;
                    right: auto;
                }
            }
            &:after {
                position: absolute;
                top: 70px;
                right: -16px;
                display: inline-block;
                border-top: 16px solid transparent;
                border-left: 16px solid #fff;
                border-right: 0 solid #fff; 
                border-bottom: 16px solid transparent;
                content: " ";

                @media (max-width: $timeline-breackingPoint) {
                    border-left-width: 0;
                    border-right-width: 15px;
                    left: -15px;
                    right: auto;
                }
            }

            .timeline-event-title {
                h5 {
                    margin-bottom: 0;
                }
            }
        }

        .timeline-badge {
            color: #fff;
            width: $timeline-badge-size;
            height: $timeline-badge-size;
            position: absolute;
            top: 54px;
            right: -$timeline-badge-size / 2;
            background: #fff;
            border-radius: 50%;
            overflow: hidden;

            border: 2px solid #fff;
            z-index: 100;

            @media (max-width: $timeline-breackingPoint) { 
                width: $timeline-badge-size / 2; 
                height: $timeline-badge-size / 2; 
                left: -6px;
                top: 70px;
                margin-left: 0;
            }

            img {
                width: 100%;
                height: auto;
                border-radius: 50%;
            }
        }

        // card right
        &.timeline-inverted {
            float: right;

            @media (max-width: $timeline-breackingPoint) {
                float: left;
                //clear: left;
                margin-top: 30px;
                margin-bottom: 30px;
            }

            .card {
                float: right;
                margin-right: 0;
                margin-left: $timeline-badge-size / 2 + 20px;

                &:before {
                    border-left-width: 0;
                    border-right-width: 15px;
                    left: -15px;
                    right: auto;
                }

                &:after {
                    border-left-width: 0;
                    border-right-width: 14px;
                    left: -14px;
                    right: auto;
                }
            }

            .timeline-badge {
                left: -$timeline-badge-size / 2;

                @media (max-width: $timeline-breackingPoint) {
                    //left: 28px;
                    left: -6px;
                }
            }
        }
    }
}