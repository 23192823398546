
.table {
    thead {
        tr {
            th {
                border-top: 0;
            }
        }
    }
}



.btn-toggle-tr {
    &.collapsed {
        i {
            transform: rotate(0deg);
        }
    }
    i {
        transition: all 0.3s ease-in-out;
        transform: rotate(90deg);
    }
}

.tr-odd {
    background-color: $table-accent-bg;
}

.tr-collapsable { 
    > th, > td {
        padding: 0;
        border-top: 0;
    }

    .tr-collapsable-body {
        .inner {
            border-left: 1px solid $table-border-color;
            margin: 0 0 15px 27px;
            padding: 0 0 0 10px;
        }
    }
}

.table-transparent {
    thead, tbody, tfoot {
        > tr {
            > th, > td {
                background: transparent;
            }
        }
    }
    background: transparent !important;
}



.table-grammatura {
    thead {

        th, td {
            border: 0;
        }
    }
    th, td {
        border: 0;
        border-top: 1px solid $gray-200;
    }
    tbody {
        
    }
    .tr-noborder {
        td, th {
            border: 0;
        }
    }
}


.kv-expand-detail-row {
    > td {
        padding-left: 50px;

        .summary {
            display: none;
        }
    }
}

.no-border-top{
    border-top: none !important;
}


.table-grammature {
    background: transparent !important;
    tr {
        background: transparent !important;
    }
    .col-grammatura {
        width: 200px;
    }
    .col-ragione-sociale {
        width: 280px;
    }
    .col-doc-pronta {
        width: 130px;
        text-align: center;
    }
    .col-criticita {
        width: 130px;
        text-align: center;
    }
    .col-stato {
        width: 130px;
        text-align: center;
    }
    .action-column {
        .btn-outline-primary {
            color: $body-color;
        }
    }
}